*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  background-image: url("./img/bg-seamless.jpg");
  /* background-size: contain; */
  background-repeat: repeat;
  background-size: cover;
  background-position: top left;
  background-image: linear-gradient(to left top, #051937, #003455, #005169, #006e6e, #028967);
  /* background-image: linear-gradient(to right bottom, #051937, #302352, #602862, #922765, #bf2b5b); */
  /* background: linear-gradient(to right bottom, #051937, #004d7a, #008793, #00bf72, #a8eb12); */
  height: 100vh;

}

h1, h2, h3{
  color: black;
}
.font-16{
  font-size: 16px;
  font-weight: 400;
}
.font-18{
  font-size: 18px;
}
.font-20{
  font-size: 20px;
}
.font-22{
  font-size: 22px;
}

.text-center{
  text-align: center;
}
.font-26{
  font-size:24px
}