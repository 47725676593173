.img-container{
    padding: 50px 0;
}
.img-container img{
    border-radius: 100%;
}

.text-center{
    text-align: center;
}
.message-section{
    border-radius: 12px 12px 0 0;
    padding: 26px 12px ;
    background-color: #EBECF0;
    margin-top: 20px;
    bottom: 0;
    position: absolute;
    max-width: 650px;
    margin: 0 auto;

    /* box-shadow: inset 1px 1px 2px rgba(186, 190, 204, 0.9), inset -1px -1px 2px #ffffff !important; */

    /* box-shadow: -2px -2px 5px #ffffff, 2px 2px 5px rgba(186, 190, 204, 0.9) !important; */

}
.message-section .message-card{
    border-radius: 12px;
    padding: 12px;
}
input{
    margin-bottom: 12px;
}
button{
    margin-top: 12px;
}
.bg-light{
    background-color: #EBECF0  !important;
}

.sul-btn{
    box-shadow: -2px -2px 5px #ffffff, 2px 2px 5px rgba(186, 190, 204, 0.9) !important;
}
.sul-btn:hover{
    box-shadow: inset 1px 1px 2px rgba(186, 190, 204, 0.9), inset -1px -1px 2px #ffffff !important;
}

.p-4{
    padding: 20px;
}
.p-3{
    padding: 12px
}

.text-left{
    text-align: left;
}
.text-right{
    text-align: right;
}
.mt-5{
    margin-top: 3rem !important;
}
.mt-4{
    margin-top: 2rem !important;
}
.mt-2{
    margin-top: 8px !important;
}
.mt-0{
    margin-top: 0px !important;
}
.mb-5{
    margin-bottom: 3rem !important;
}
.mb-4{
    margin-bottom: 2rem !important;
}
.mx-4{
    margin-left: 1rem !important;
    margin-right: 1rem !important;
}
.font-bold{
    font-weight: bold;
}
.text-important{
    color: #ff2a94;
    font-weight: 900 !important;
}
.font-14{
    font-size: 14px;
}
.text-white{
    color: white;
}

.text-underline{
    text-decoration: underline;
}
.bg-white{
    background-color: #ffffff;
    border-radius: 5px;
}