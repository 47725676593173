@media (min-width: 1025px)  {
  
      
    .message-section{
       width: 650px;
    }
  }

  
  @media (min-width: 768px) and (max-width: 1024px) {
    
      
    .message-section{
        width: 650px;
    }
  }
  

  
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    
    
  }

@media (min-width: 481px) and (max-width: 767px) {
  
    .message-section{
        width: 100%;
    }
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    
    /* CSS */
    .message-section{
        width: 100%;
    }
  }