// animation
@keyframes FLOW-ICON {
    0%{
        transform: scale(.95);
    }
    100%{
        transform: scale(0.95);
    }
}

.servers-icon {
    position: relative;
    display: inline-block;
    text-align: center;
    padding-top: 15px;
    width: 215px;
    height: 215px;
    border-radius: 500px;
    background-repeat: repeat-x;
    background-image: -webkit-linear-gradient(120deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.01));
    background-image: -o-linear-gradient(120deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.01));
    background-image: linear-gradient(120deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.01));
    -webkit-box-shadow: 0 1px 46px rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 46px rgba(0, 0, 0, 0.15);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.3s cubic-bezier(0.34, 1.61, 0.7, 1);
    transition: all 0.3s cubic-bezier(0.34, 1.61, 0.7, 1);
    // animation: FLOW-ICON 1s infinite;
}

.servers-icon:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 500px;
    background-color: #fff;
    -webkit-box-shadow: 0 1px 46px rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 46px rgba(0, 0, 0, 0.15);
    opacity: 0.02;
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    -webkit-transition: all 0.7s cubic-bezier(0.34, 1.61, 0.7, 1);
    transition: all 0.7s cubic-bezier(0.34, 1.61, 0.7, 1);
    animation: FLOW-BEFORE 1s infinite;
}
@keyframes FLOW-BEFORE {
    0%{
        transform: scale(1.5);
    }
    100%{
        transform: scale(1.3);
    }
}

.servers-icon:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 500px;
    background-color: #fff;
    -webkit-box-shadow: 0 1px 46px rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 46px rgba(0, 0, 0, 0.15);
    opacity: 0.06;
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: scale(1.25);
    -moz-transform: scale(1.25);
    -ms-transform: scale(1.25);
    transform: scale(1.25);
    -webkit-transition: all 0.5s cubic-bezier(0.34, 1.61, 0.7, 1);
    transition: all 0.5s cubic-bezier(0.34, 1.61, 0.7, 1);
    animation: FLOW-AFTER 1s infinite;
}
@keyframes FLOW-AFTER {
    0%{
        transform: scale(1.25);
    }
    100%{
        transform: scale(1.15);
    }
}
.servers-icon img {
    width: 294px;
    width: 85%;
}

.servers-icon:hover {
    -webkit-transform: scale(0.95);
    -moz-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
}

.servers-icon:hover:before {
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
}

.servers-icon:hover:after {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    -ms-transform: scale(1.15);
    transform: scale(1.15);
}


// .servers-icon {
//     position: relative;
//     display: inline-block;
//     text-align: center;
//     padding-top: 25px;
//     width: 245px;
//     height: 245px;
//     border-radius: 500px;
//     background-repeat: repeat-x;
//     background-image: -webkit-linear-gradient(120deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.01));
//     background-image: -o-linear-gradient(120deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.01));
//     background-image: linear-gradient(120deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.01));
//     -webkit-box-shadow: 0 1px 46px rgba(0, 0, 0, 0.15);
//     box-shadow: 0 1px 46px rgba(0, 0, 0, 0.15);
//     -webkit-transform-origin: center center;
//     -moz-transform-origin: center center;
//     -ms-transform-origin: center center;
//     transform-origin: center center;
//     -webkit-transform: scale(1);
//     -moz-transform: scale(1);
//     -ms-transform: scale(1);
//     transform: scale(1);
//     -webkit-transition: all 0.3s cubic-bezier(0.34, 1.61, 0.7, 1);
//     transition: all 0.3s cubic-bezier(0.34, 1.61, 0.7, 1);
// }

// .servers-icon:before {
//     position: absolute;
//     content: "";
//     width: 100%;
//     height: 100%;
//     left: 0;
//     top: 0;
//     border-radius: 500px;
//     background-color: #fff;
//     -webkit-box-shadow: 0 1px 46px rgba(0, 0, 0, 0.15);
//     box-shadow: 0 1px 46px rgba(0, 0, 0, 0.15);
//     opacity: 0.02;
//     -webkit-transform-origin: center center;
//     -moz-transform-origin: center center;
//     -ms-transform-origin: center center;
//     transform-origin: center center;
//     -webkit-transform: scale(1.5);
//     -moz-transform: scale(1.5);
//     -ms-transform: scale(1.5);
//     transform: scale(1.5);
//     -webkit-transition: all 0.7s cubic-bezier(0.34, 1.61, 0.7, 1);
//     transition: all 0.7s cubic-bezier(0.34, 1.61, 0.7, 1);
// }

// .servers-icon:after {
//     position: absolute;
//     content: "";
//     width: 100%;
//     height: 100%;
//     left: 0;
//     top: 0;
//     border-radius: 500px;
//     background-color: #fff;
//     -webkit-box-shadow: 0 1px 46px rgba(0, 0, 0, 0.15);
//     box-shadow: 0 1px 46px rgba(0, 0, 0, 0.15);
//     opacity: 0.06;
//     -webkit-transform-origin: center center;
//     -moz-transform-origin: center center;
//     -ms-transform-origin: center center;
//     transform-origin: center center;
//     -webkit-transform: scale(1.25);
//     -moz-transform: scale(1.25);
//     -ms-transform: scale(1.25);
//     transform: scale(1.25);
//     -webkit-transition: all 0.5s cubic-bezier(0.34, 1.61, 0.7, 1);
//     transition: all 0.5s cubic-bezier(0.34, 1.61, 0.7, 1);
// }
// .servers-icon img {
//     width: 294px;
//     width: 85%;
// }

// .servers-icon:hover {
//     -webkit-transform: scale(0.95);
//     -moz-transform: scale(0.95);
//     -ms-transform: scale(0.95);
//     transform: scale(0.95);
// }

// .servers-icon:hover:before {
//     -webkit-transform: scale(1.3);
//     -moz-transform: scale(1.3);
//     -ms-transform: scale(1.3);
//     transform: scale(1.3);
// }

// .servers-icon:hover:after {
//     -webkit-transform: scale(1.15);
//     -moz-transform: scale(1.15);
//     -ms-transform: scale(1.15);
//     transform: scale(1.15);
// }