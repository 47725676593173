@media (max-width: 600px){
    aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-below{
        position: fixed !important;
        z-index: 5 !important;
    }
}


.menu-backup{
    height: 100vh;
    flex: 0 0 200px;
    max-width: 200px;
    min-width: 200px;
    position: fixed;
    width: 200px;
}

.align-items-center {
    align-items: center!important;
}

.d-flex {
    display: flex!important;
}
@media (min-width: 768px)  {
    
    .socials{
        position: absolute;
        bottom:0;
        margin-top: 0;
    
    }
  }
.socials{
    margin-top: 140px;
    a {
        // color: #64ffda;
        text-decoration: none;
        background-color: transparent;
        margin-top: 18px;
        div{
            font-size: 18px;
        }
    }

}
.ml-3{
    margin-left: 1rem !important;
}
.ml-2, .mx-2 {
    margin-left: .5rem!important;
}
.mb-2, .my-2 {
    margin-bottom: .5rem!important;
}
.ml-4{
    margin-left: 1.25rem !important;
}